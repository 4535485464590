<template>
	<v-row>
		<v-col cols="12">
			<v-card>
				<v-card-text>
					<v-form>
						<v-row class="mb-n10">
							<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
								<v-sheet class="text-center" height="100%">
									<v-row class="pa-3">
										<v-col cols="12" class="mb-n10" v-if="usuario.idgrupo != 6">
											<v-autocomplete
												:items="regionais"
												clearable
												item-text="regional"
												item-value="idregional"
												label="Regional"
												outlined
												dense
												:disabled="carregando"
												v-model="busca.idregional"
												@change="listarEmpresas()"
											></v-autocomplete>
										</v-col>
										<v-col cols="12">
											<v-autocomplete
												:items="filiais.lista"
												clearable
												item-text="filial"
												item-value="idfilial"
												label="Filial"
												outlined
												dense
												:disabled="carregando"
												v-model="busca.idfiliais"
												multiple
											>
												<template v-slot:selection="{ item, index }">
													<template v-if="index === 0">{{ item.filial }}</template>
													<span
														v-if="index === 1"
														class="grey--text text-caption ml-1"
													>(+{{ busca.idfiliais.length - 1 }})</span>
												</template>
											</v-autocomplete>
										</v-col>
										<v-col cols="6">
											<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
										</v-col>
										<v-col cols="6">
											<v-btn text class="mt-n3" color="primary" @click="get(), sheet = !sheet">Filtrar</v-btn>
										</v-col>
									</v-row>
								</v-sheet>
							</v-bottom-sheet>
							<v-col class="pr-1">
								<v-select
									class="d-inline-flex d-md-none"
									append-outer-icon="mdi-menu"
									@click:append-outer="sheet = !sheet"
									label="Período"
									outlined
									dense
									:items="periodos"
									item-text="descricao"
									item-value="idmeta"
									v-model="busca.idmeta"
								></v-select>
								<v-select
									class="d-none d-md-block"
									label="Período"
									outlined
									dense
									:items="periodos"
									item-text="descricao"
									item-value="idmeta"
									v-model="busca.idmeta"
								></v-select>
							</v-col>
							<v-col class="d-none d-md-block px-1" v-if="usuario.idgrupo != 6">
								<v-autocomplete
									:items="regionais"
									clearable
									item-text="regional"
									item-value="idregional"
									label="Regional"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idregional"
									@change="listarEmpresas()"
								></v-autocomplete>
							</v-col>
							<v-col class="d-none d-md-block px-1">
								<v-autocomplete
									:items="filiais.lista"
									clearable
									item-text="filial"
									item-value="idfilial"
									label="Filial"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idfiliais"
									multiple
								>
									<template v-slot:selection="{ item, index }">
										<template v-if="index === 0">{{ item.filial }}</template>
										<span
											v-if="index === 1"
											class="grey--text text-caption ml-1"
										>(+{{ busca.idfiliais.length - 1 }})</span>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col cols="auto" class="pl-1">
								<v-btn
									:disabled="carregando"
									class="ml-2 mt-1"
									color="primary"
									elevation="0"
									fab
									x-small
									@click="get()"
								>
									<v-icon>mdi-magnify</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
		</v-col>
		<template v-if="exibirFilial && dados.filiais">
			<v-col cols="12" md="12">
				<v-row class="px-2">
					<v-col class="pa-1">
						<CardDashboardBonificacao
							classContent="py-1 px-3 text-center font-weight-bold text--white text-h6 text-truncate"
							:loading="carregando"
							title="Bonificação no período"
						>
							<template>
								{{dados.meta[0].bonificacaototal | formataDinheiro}}
								<v-row no-gutters>
									<v-col cols="12" class="text-caption font-weight-light">
										Bonificações atingidas:
										<span
											class="font-weight-bold"
										>{{dados.meta[0].bonificacoesatingidas}}</span>
									</v-col>
									<v-col cols="12" class="text-caption font-weight-light">
										Meta bonificação:
										<span
											class="font-weight-bold"
										>{{dados.meta[0].bonificacao | formataDinheiro}}</span>
									</v-col>
								</v-row>
							</template>
						</CardDashboardBonificacao>
					</v-col>
					<v-col class="pa-1">
						<CardDashboardBonificacao
							classContent="py-1 px-3 text-center font-weight-bold text--white text-h6 text-truncate"
							:loading="carregando"
							:title="`Lucratividade no período (${dreStatus()})`"
						>
							<template>
								<span class="font-weight-light">Meta lucratividade ano:</span>
								<span
									class="font-weight-bold"
								>{{resumoMetas(dados.filiais[0].idempresa, 'LUCRATIVIDADE ANO')[0].meta | formataDinheiro}}</span>
								<v-row no-gutters>
									<v-col cols="12" class="text-caption font-weight-light">
										Lucratividade acumulada no período:
										<span
											class="font-weight-bold"
										>{{dados.filiais[0].lucratividadeano | formataDinheiro}}</span>
									</v-col>
									<v-col cols="12" class="text-caption font-weight-light">
										Lucratividade positiva:
										<span
											class="font-weight-bold"
										>{{dados.filiais[0].lucratividadepositiva ? 'Sim':'Não' }}</span>
									</v-col>
								</v-row>
							</template>
						</CardDashboardBonificacao>
					</v-col>
				</v-row>
				<v-row class="pa-2">
					<v-col class="pa-1">
						<CardDashboardBonificacao
							classContent="py-1 px-3 text-center font-weight-bold text--white text-h6 text-truncate"
							:loading="carregando"
							title="Meta de vendas"
							:color="atingimentoCor(dados.filiais[0].lucratividadepositiva, dados.filiais[0].perrealizado, `${resumoMetas(dados.filiais[0].idempresa, 'META DE VENDAS')[0].meta}`)"
						>
							<template>
								<template
									v-if="dados.filiais[0].perrealizado > resumoMetas(dados.filiais[0].idempresa, 'META DE VENDAS')[0].meta"
								>{{atingimentoValor(dados.filiais[0].idempresa, 'META DE VENDAS') | formataDinheiro}}</template>
								<template v-else>Não atingido</template>
								<br />
								<v-row no-gutters>
									<v-col cols="6" class="text-caption font-weight-light">
										Peso:
										<span
											class="font-weight-bold"
										>{{resumoMetas(dados.filiais[0].idempresa, 'META DE VENDAS')[0].peso.toFixed(2)}}%</span>
									</v-col>
									<v-col cols="6" class="text-caption font-weight-light">
										Objetivo:
										<span
											class="font-weight-bold"
										>{{resumoMetas(dados.filiais[0].idempresa, 'META DE VENDAS')[0].meta.toFixed(2)}}%</span>
									</v-col>
									<v-col cols="12" class="text-caption font-weight-light">
										Atingido:
										<span class="font-weight-bold">{{dados.filiais[0].perrealizado.toFixed(2)}}%</span>
									</v-col>
								</v-row>
							</template>
						</CardDashboardBonificacao>
					</v-col>
					<v-col class="pa-1">
						<CardDashboardBonificacao
							classContent="py-1 px-3 text-center font-weight-bold text--white text-h6 text-truncate"
							:loading="carregando"
							title="Margem de lucratividade"
							:color="atingimentoCor(dados.filiais[0].lucratividadepositiva, dados.filiais[0].permargem, `${resumoMetas(dados.filiais[0].idempresa, 'MARGEM DE LUCRATIVIDADE')[0].meta}`)"
						>
							<template>
								<template
									v-if="dados.filiais[0].permargem > resumoMetas(dados.filiais[0].idempresa, 'MARGEM DE LUCRATIVIDADE')[0].meta"
								>{{atingimentoValor(dados.filiais[0].idempresa, 'MARGEM DE LUCRATIVIDADE') | formataDinheiro}}</template>
								<template v-else>Não atingido</template>
								<br />
								<v-row no-gutters>
									<v-col cols="6" class="text-caption font-weight-light">
										Peso:
										<span
											class="font-weight-bold"
										>{{resumoMetas(dados.filiais[0].idempresa, 'MARGEM DE LUCRATIVIDADE')[0].peso.toFixed(2)}}%</span>
									</v-col>
									<v-spacer />
									<v-col cols="6" class="text-caption font-weight-light">
										Objetivo:
										<span
											class="font-weight-bold"
										>{{resumoMetas(dados.filiais[0].idempresa, 'MARGEM DE LUCRATIVIDADE')[0].meta.toFixed(2)}}%</span>
									</v-col>
									<v-col cols="12" class="text-caption font-weight-light">
										Atingido:
										<span class="font-weight-bold">{{dados.filiais[0].permargem.toFixed(2)}}%</span>
									</v-col>
								</v-row>
							</template>
						</CardDashboardBonificacao>
					</v-col>
					<v-col class="pa-1">
						<CardDashboardBonificacao
							classContent="py-1 px-3 text-center font-weight-bold text--white text-h6 text-truncate"
							:loading="carregando"
							title="Despesa máxima sobre vendas"
							:color="atingimentoCor(dados.filiais[0].lucratividadepositiva, `${resumoMetas(dados.filiais[0].idempresa, 'DESPESA MÁXIMA SOBRE VENDAS')[0].meta}`, dados.filiais[0].perdespesas)"
						>
							<template>
								<template
									v-if="dados.filiais[0].perdespesas < resumoMetas(dados.filiais[0].idempresa, 'DESPESA MÁXIMA SOBRE VENDAS')[0].meta"
								>{{atingimentoValor(dados.filiais[0].idempresa, 'DESPESA MÁXIMA SOBRE VENDAS') | formataDinheiro}}</template>
								<template v-else>Não atingido</template>
								<br />
								<v-row no-gutters>
									<v-col cols="6" class="text-caption font-weight-light">
										Peso:
										<span
											class="font-weight-bold"
										>{{resumoMetas(dados.filiais[0].idempresa, 'DESPESA MÁXIMA SOBRE VENDAS')[0].peso.toFixed(2)}}%</span>
									</v-col>
									<v-spacer />
									<v-col cols="6" class="text-caption font-weight-light">
										Objetivo:
										<span
											class="font-weight-bold"
										>{{resumoMetas(dados.filiais[0].idempresa, 'DESPESA MÁXIMA SOBRE VENDAS')[0].meta.toFixed(2)}}%</span>
									</v-col>
									<v-col cols="12" class="text-caption font-weight-light">
										Atingido:
										<span class="font-weight-bold">{{dados.filiais[0].perdespesas.toFixed(2)}}%</span>
									</v-col>
								</v-row>
							</template>
						</CardDashboardBonificacao>
					</v-col>
					<v-col class="pa-1">
						<CardDashboardBonificacao
							classContent="py-1 px-3 text-center font-weight-bold text--white text-h6 text-truncate"
							:loading="carregando"
							title="% Variação de estoque (c/ rotativos)"
							:color="atingimentoCor(dados.filiais[0].lucratividadepositiva, `${resumoMetas(dados.filiais[0].idempresa, 'PERCA DE ESTOQUE (COM ROTATIVOS)')[0].meta}`, dados.filiais[0].perperda * -1)"
						>
							<template>
								<template
									v-if="dados.filiais[0].perperda * -1 < resumoMetas(dados.filiais[0].idempresa, 'PERCA DE ESTOQUE (COM ROTATIVOS)')[0].meta"
								>{{atingimentoValor(dados.filiais[0].idempresa, 'PERCA DE ESTOQUE (COM ROTATIVOS)') | formataDinheiro}}</template>
								<template v-else>Não atingido</template>
								<br />
								<v-row no-gutters>
									<v-col cols="6" class="text-caption font-weight-light">
										Peso:
										<span
											class="font-weight-bold"
										>{{resumoMetas(dados.filiais[0].idempresa, 'PERCA DE ESTOQUE (COM ROTATIVOS)')[0].peso.toFixed(2)}}%</span>
									</v-col>
									<v-spacer />
									<v-col cols="6" class="text-caption font-weight-light">
										Objetivo:
										<span
											class="font-weight-bold"
										>{{resumoMetas(dados.filiais[0].idempresa, 'PERCA DE ESTOQUE (COM ROTATIVOS)')[0].meta.toFixed(2)}}%</span>
									</v-col>
									<v-col cols="12" class="text-caption font-weight-light">
										Atingido:
										<span
											class="font-weight-bold"
											v-if="dados.filiais[0].perperda"
										>{{dados.filiais[0].perperda.toFixed(2)}}%</span>
									</v-col>
								</v-row>
							</template>
						</CardDashboardBonificacao>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12">
				<v-card>
					<v-card-title>Percentual de atingimento de metas</v-card-title>
					<v-card-text v-if="!carregando">
						<v-row v-if="dados.filiais">
							<v-col cols="12" md="3" class="text-center">
								<span class="text-caption font-weight-bold black--text">Meta de vendas</span>
								<GraficoBarDashboardVue
									:dataSeries="[
										{
											name: dados.filiais[0].empresa.split('-')[dados.filiais[0].empresa.split('-').length - 1],
											data: [resumoMetas(dados.filiais[0].idempresa, 'META DE VENDAS')[0].meta.toFixed(2), dados.filiais[0].perrealizado.toFixed(2)]
										},                            
									]"
									:categories="['Objetivo', 'Atingido']"
									:horizontal="false"
									:money="false"
									:porcentagem="true"
									height="200"
									width="95%"
									grid="clean"
									:distributed="true"
									:dataLabels="{ position : 'bottom', orientation : 'horizontal', offsetY: 20 }"
								/>
							</v-col>
							<v-col cols="12" md="3" class="text-center">
								<span class="text-caption font-weight-bold black--text">Margem de lucratividade</span>
								<GraficoBarDashboardVue
									:dataSeries="[
										{
											name: dados.filiais[0].empresa.split('-')[dados.filiais[0].empresa.split('-').length - 1],
											data: [resumoMetas(dados.filiais[0].idempresa, 'MARGEM DE LUCRATIVIDADE')[0].meta.toFixed(2), dados.filiais[0].permargem.toFixed(2)]
										},                           
									]"
									:categories="['Objetivo', 'Atingido']"
									:horizontal="false"
									:money="false"
									:porcentagem="true"
									height="200"
									width="95%"
									grid="clean"
									:distributed="true"
									:dataLabels="{ position : 'bottom', orientation : 'horizontal', offsetY: 20 }"
								/>
							</v-col>
							<v-col cols="12" md="3" class="text-center">
								<span class="text-caption font-weight-bold black--text">Despesa máx s/ vendas</span>
								<GraficoBarDashboardVue
									:dataSeries="[
										{
											name: dados.filiais[0].empresa.split('-')[dados.filiais[0].empresa.split('-').length - 1],
											data: [resumoMetas(dados.filiais[0].idempresa, 'DESPESA MÁXIMA SOBRE VENDAS')[0].meta.toFixed(2), dados.filiais[0].perdespesas.toFixed(2)]
										},                            
									]"
									:categories="['Objetivo', 'Atingido']"
									:horizontal="false"
									:money="false"
									:porcentagem="true"
									height="200"
									width="95%"
									grid="clean"
									:distributed="true"
									:dataLabels="{ position : 'bottom', orientation : 'horizontal', offsetY: 20 }"
								/>
							</v-col>
							<v-col cols="12" md="3" class="text-center">
								<span class="text-caption font-weight-bold black--text">% Variação de estoque</span>
								<GraficoBarDashboardVue
									:dataSeries="[
										{
											name: dados.filiais[0].empresa.split('-')[dados.filiais[0].empresa.split('-').length - 1],
											data: [resumoMetas(dados.filiais[0].idempresa, 'PERCA DE ESTOQUE (COM ROTATIVOS)')[0].meta.toFixed(2), dados.filiais[0].perperda.toFixed(2)]
										},                           
									]"
									:categories="['Objetivo', 'Atingido']"
									:horizontal="false"
									:money="false"
									:porcentagem="true"
									height="200"
									width="95%"
									grid="clean"
									:distributed="true"
									:dataLabels="{ position : 'bottom', orientation : 'horizontal', offsetY: 20 }"
								/>
							</v-col>
						</v-row>
						<span v-else>Nenhum registro encontrado</span>
					</v-card-text>
					<v-card-text v-else>
						<v-skeleton-loader class="mx-auto" width="100%" height="250" type="image, image"></v-skeleton-loader>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12">
				<v-card dark color="primary">
					<v-progress-linear indeterminate absolute :active="carregando" />
					<v-card-title class="text-truncate">Resumo mensal</v-card-title>
					<v-card-text v-if="carregando">
						<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
					</v-card-text>
					<v-card-text v-else>
						<v-simple-table v-if="dados.meses" light dense class="rounded-0">
							<thead>
								<tr class="grey lighten-1">
									<th>Mês</th>
									<th>Meta</th>
									<th>Realizado</th>
									<th>% realizado</th>
									<th>Margem</th>
									<th>Lucro</th>
									<th>% despesa</th>
									<th class="text-center">DRE</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(m, i) in dados.meses" :key="i">
									<td>{{m.mes > 9 ? m.mes : `0${m.mes}`}}</td>
									<td>{{m.meta | formataDinheiro}}</td>
									<td>{{m.realizado | formataDinheiro}}</td>
									<td>{{m.perrealizado.toFixed(2)}} %</td>
									<td>{{m.permargem.toFixed(2)}} %</td>
									<td>{{m.lucro | formataDinheiro}}</td>
									<td>{{m.perdespesas.toFixed(2)}} %</td>
									<td class="text-center">
										<v-chip v-if="m.dre" color="success" small>Fechada</v-chip>
										<v-chip v-else color="error" small>Em aberto</v-chip>
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr class="font-weight-bold">
									<td>Total</td>
									<td>{{dados.meses.map(v => v.meta).reduce((a,v) => a + v) | formataDinheiro}}</td>
									<td>{{dados.meses.map(v => v.realizado).reduce((a,v) => a + v) | formataDinheiro}}</td>
									<td>{{((dados.meses.map(v => v.realizado).reduce((a,v) => a + v)/dados.meses.map(v => v.meta).reduce((a,v) => a + v)) * 100).toFixed(2)}} %</td>
									<td>{{((dados.meses.map(v => v.margem).reduce((a,v) => a + v)/dados.meses.map(v => v.realizado).reduce((a,v) => a + v)) * 100).toFixed(2)}} %</td>
									<td>{{dados.meses.map(v => v.lucro).reduce((a,v) => a + v) | formataDinheiro}}</td>
									<td>{{((dados.meses.map(v => v.despesas).reduce((a,v) => a + v)/dados.meses.map(v => v.receita).reduce((a,v) => a + v)) * 100).toFixed(2)}} %</td>
									<td></td>
								</tr>
							</tfoot>
						</v-simple-table>
						<span v-else>Nenhum registro encontrado</span>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12">
				<v-card dark color="primary">
					<v-progress-linear indeterminate absolute :active="carregando" />
					<v-card-title class="text-truncate">Perda de mercadorias sobre DRE</v-card-title>
					<v-card-text v-if="carregando">
						<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
					</v-card-text>
					<v-card-text v-else>
						<v-simple-table v-if="dados.filiais[0]" light dense class="rounded-0">
							<thead>
								<tr class="grey lighten-1">
									<th>Perda</th>
									<th>Sobra</th>
									<th>Saldo</th>
									<th>Estoque</th>
									<th>% perda</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{{dados.filiais[0].perca | formataDinheiro}}</td>
									<td>{{dados.filiais[0].sobra | formataDinheiro}}</td>
									<td>{{dados.filiais[0].saldo | formataDinheiro}}</td>
									<td>{{dados.filiais[0].estoque | formataDinheiro}}</td>
									<td>{{dados.filiais[0].perperda.toFixed(2)}} %</td>
								</tr>
							</tbody>
						</v-simple-table>
						<span v-else>Nenhum registro encontrado</span>
					</v-card-text>
				</v-card>
			</v-col>
		</template>
		<v-col cols="12">
			<v-card dark color="primary">
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title class="text-truncate">Resumo bonificação</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<v-simple-table v-if="dados.filiais" light dense class="rounded-0">
						<thead>
							<tr class="grey lighten-1">
								<th>Filial</th>
								<th class="text-center">DRE</th>
								<th>Lucro no período</th>
								<th>Lucro no ano</th>
								<th>Lucratividade positiva</th>
								<th>Meta de vendas (objetivo/atingido)</th>
								<th>Margem (objetivo/atingido)</th>
								<th>Despesas (objetivo/atingido)</th>
								<th>% var. de estoque (objetivo/atingido)</th>
								<th>Valor bonificação</th>
							</tr>
						</thead>
						<tbody v-if="dados.filiais.length > 0">
							<tr v-for="(u, i) in dados.filiais" :key="i">
								<td v-if="u.idempresa == 4">Castelo</td>
								<td class="text-truncate" v-else>{{u.empresa.split("-")[u.empresa.split("-").length - 1]}}</td>
								<td class="text-center">
									<v-chip v-if="u.dre" color="success" small>Fechada</v-chip>
									<v-chip v-else color="error" small>Em aberto</v-chip>
								</td>
								<td class="text-truncate">{{u.lucro | formataDinheiro}}</td>
								<td class="text-truncate">{{u.lucratividadeano | formataDinheiro}}</td>
								<td class="text-center">
									<v-chip v-if="u.lucratividadepositiva" color="success" small>SIM</v-chip>
									<v-chip v-else color="error" small>NÃO</v-chip>
								</td>
								<td
									class="text-truncate text-center"
								>{{resumoMetas(u.idempresa, 'META DE VENDAS')[0].meta.toFixed(2)}} % / {{u.perrealizado.toFixed(2)}} %</td>
								<td
									class="text-truncate text-center"
								>{{resumoMetas(u.idempresa, 'MARGEM DE LUCRATIVIDADE')[0].meta.toFixed(2)}} % / {{u.permargem.toFixed(2)}} %</td>
								<td
									class="text-truncate text-center"
								>{{resumoMetas(u.idempresa, 'DESPESA MÁXIMA SOBRE VENDAS')[0].meta.toFixed(2)}} % / {{u.perdespesas.toFixed(2)}} %</td>
								<td
									class="text-truncate text-center"
								>{{resumoMetas(u.idempresa, 'PERCA DE ESTOQUE (COM ROTATIVOS)')[0].meta.toFixed(2)}} % / {{u.perperda.toFixed(2)}} %</td>
								<td
									class="text-truncate"
									v-if="dados.meta.filter(v => v.lucrutividadepositiva && v.idempresa == u.idempresa).length > 0"
								>{{dados.meta.filter(v => v.idempresa == u.idempresa)[0].bonificacaototal | formataDinheiro }}</td>
								<td v-else>{{0 | formataDinheiro}}</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td colspan="8" class="text-center">Nenhum registro encontrato</td>
							</tr>
						</tbody>
						<tfoot>
							<tr class="font-weight-bold">
								<td>Total</td>
								<td></td>
								<td
									class="text-truncate"
								>{{dados.filiais.map(v => v.lucro).reduce((a,v) => a + v) | formataDinheiro}}</td>
								<td
									class="text-truncate"
								>{{dados.filiais.map(v => v.lucratividadeano).reduce((a,v) => a + v) | formataDinheiro}}</td>
								<td></td>
								<td
									class="text-center"
								>{{(dados.filiais.map(v => v.realizado).reduce((a,v) => a + v) / dados.filiais.map(v => v.meta).reduce((a,v) => a + v) * 100).toFixed(2)}} %</td>
								<td
									class="text-center"
								>{{(dados.filiais.map(v => v.margem).reduce((a,v) => a + v) / dados.filiais.map(v => v.realizado).reduce((a,v) => a + v) * 100).toFixed(2)}} %</td>
								<td
									class="text-center"
								>{{(dados.filiais.map(v => v.despesas).reduce((a,v) => a + v) / dados.filiais.map(v => v.receita).reduce((a,v) => a + v) * 100).toFixed(2)}} %</td>
								<td
									v-if="((dados.filiais.map(v => v.saldo).reduce((a,v) => a + v) / dados.filiais.map(v => v.estoque).reduce((a,v) => a + v) * 100).toFixed(2)) >= 0"
									class="text-center"
								>{{(dados.filiais.map(v => v.saldo).reduce((a,v) => a + v) / dados.filiais.map(v => v.estoque).reduce((a,v) => a + v) * 100).toFixed(2)}} %</td>
								<td
									v-else
									class="text-center"
								>{{((dados.filiais.map(v => v.saldo).reduce((a,v) => a + v) / dados.filiais.map(v => v.estoque).reduce((a,v) => a + v) * 100).toFixed(2)) * -1}} %</td>
								<td
									v-if="dados.meta.filter(v => v.lucrutividadepositiva).length > 0"
								>{{dados.meta.filter(v => v.lucrutividadepositiva).map(v => v.bonificacaototal).reduce((a,v) => a + v) | formataDinheiro}}</td>
								<td v-else>{{0 | formataDinheiro}}</td>
							</tr>
						</tfoot>
					</v-simple-table>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import CardDashboardBonificacao from "../Widgets/CardDashboardBonificacao";
import GraficoBarDashboardVue from "../Widgets/GraficoBarDashboard";

export default {
	name: "DashboardBonificacao",
	components: { CardDashboardBonificacao, GraficoBarDashboardVue },
	data: () => ({
		sheet: false,
		carregando: false,
		busca: {
			idfiliais: 1,
			idmeta: 0,
			idregional: "",
		},
		dados: {},
		filiais: {},
		regionais: [],
		periodos: [],
		exibirFilial: false,
	}),
	methods: {
		get() {
			let idfiliais = null;
			if (this.busca.idfiliais.length > 0) {
				idfiliais = this.busca.idfiliais.join(",");
			}
			this.dados = {};
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dashboard/bonificacao`, {
					idmeta: this.busca.idmeta || null,
					idregional: this.busca.idregional
						? `${this.busca.idregional}`
						: null,
					filial: idfiliais || null,
				})
				.then((res) => {
					this.dados = res.data;
					if (this.dados.filiais) {
						this.exibirFilial = this.dados.filiais.length == 1;
						this.dados.meta.forEach((v) => {
							if (
								this.dados.filiais.filter(
									(f) => f.idempresa == v.idempresa
								).length > 0
							) {
								v.lucrutividadepositiva =
									this.dados.filiais.filter(
										(f) => f.idempresa == v.idempresa
									)[0].lucratividadepositiva;
							}
							v.bonificacaototal = 0;
							v.bonificacoesatingidas = 0;
							v.metas.map((m) => {
								if (
									m.descmeta == "META DE VENDAS" &&
									this.dados.filiais.filter(
										(f) =>
											f.idempresa == v.idempresa &&
											f.perrealizado >= m.meta
									).length > 0
								) {
									v.bonificacaototal +=
										v.bonificacao * (m.peso / 100);
									v.bonificacoesatingidas++;
								}
								if (
									m.descmeta == "MARGEM DE LUCRATIVIDADE" &&
									this.dados.filiais.filter(
										(f) =>
											f.idempresa == v.idempresa &&
											f.permargem >= m.meta
									).length > 0
								) {
									v.bonificacaototal +=
										v.bonificacao * (m.peso / 100);
									v.bonificacoesatingidas++;
								}
								if (
									m.descmeta ==
										"DESPESA MÁXIMA SOBRE VENDAS" &&
									this.dados.filiais.filter(
										(f) =>
											f.idempresa == v.idempresa &&
											f.perdespesas <= m.meta
									).length > 0
								) {
									v.bonificacaototal +=
										v.bonificacao * (m.peso / 100);
									v.bonificacoesatingidas++;
								}
								console.log(
									this.dados.filiais.filter(
										(f) =>
											f.idempresa == v.idempresa &&
											f.perperda * -1 <= m.meta
									)
								);
								if (
									m.descmeta ==
										"PERCA DE ESTOQUE (COM ROTATIVOS)" &&
									this.dados.filiais.filter(
										(f) =>
											f.idempresa == v.idempresa &&
											f.perperda * -1 <= m.meta
									).length > 0
								) {
									v.bonificacaototal +=
										v.bonificacao * (m.peso / 100);
									v.bonificacoesatingidas++;
								}
							});
						});
					}
					this.carregando = false;
				})
				.catch((err) => {
					console.error(err);
					this.carregando = false;
				});
		},
		listarEmpresas() {
			this.busca.idfiliais = [];
			return axios
				.post(`${this.backendUrl}filial/listar`, {
					limit: 9999,
					offset: 0,
					idregional: this.busca.idregional || null,
					busca: null,
				})
				.then((res) => {
					this.filiais = res.data;
					this.filiais.lista = this.filiais.lista.filter((v) => {
						return (
							v.idfilial !== 6 &&
							v.idfilial !== 12 &&
							v.idfilial !== 13 &&
							v.idfilial !== 22 &&
							v.idfilial !== 25 &&
							v.idfilial !== 29 &&
							v.idfilial !== 32 &&
							v.idfilial !== 33 &&
							v.idfilial !== 34 &&
							v.idfilial !== 37
						);
					});
				});
		},
		listarRegionais() {
			return axios
				.post(`${this.backendUrl}filial/regional/listar`)
				.then((res) => {
					this.regionais = res.data;
				});
		},
		listarPeriodos() {
			return axios
				.post(`${this.backendUrl}dashboard/bonificacao/periodos`)
				.then((res) => {
					this.periodos = res.data;
					this.busca.idmeta = Math.max.apply(
						null,
						this.periodos.map((v) => v.idmeta)
					);
				});
		},
		resumoMetas(idempresa, campo) {
			return this.dados.meta
				.filter((v) => v.idempresa == idempresa)[0]
				.metas.filter((v) => v.descmeta == campo);
		},
		atingimentoValor(idempresa, campo) {
			let bonificacao = this.dados.meta.filter(
				(v) => v.idempresa == idempresa
			)[0].bonificacao;
			let peso = this.dados.meta
				.filter((v) => v.idempresa == idempresa)[0]
				.metas.filter((v) => v.descmeta == campo)[0].peso;
			return bonificacao * (peso / 100);
		},
		atingimentoCor(lucrutividadePositiva, valor1, valor2) {
			if (lucrutividadePositiva) {
				if (valor1 > valor2) {
					return "green darken-3";
				} else {
					return "primary";
				}
			} else {
				if (valor1 > valor2) {
					return "red darken-3";
				} else {
					return "primary";
				}
			}
		},
		dreStatus() {
			if (this.dados.filiais) {
				if (!this.dados.filiais[0].dre) {
					return "DRE em aberto";
				} else {
					return "DRE fechada";
				}
			}
		},
		async init() {
			await this.listarEmpresas();
			await this.listarRegionais();
			await this.listarPeriodos();
			await this.get();
		},
	},
	computed: {
		...mapState(["backendUrl", "usuario"]),
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
.border-col {
	border: 1px solid #023449;
}
</style>
